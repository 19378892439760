import { Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { validation } from '../../../helper/validation';
import Notification from '../../../helper/screens/Notification';
import Popup from '../../../helper/screens/Popup';
import { useForm, Controller as FormController } from "react-hook-form";

import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import UserUnit from '../../../communicator/http/User';
import PermissionUnit from '../../../communicator/http/Permission';
import { removeFromArrayByValue } from '../../../helper/functions/array';
import { translate } from '../../../helper/multilingual';
import DatePicker from '../../../elements/datepicker';
import CompanyUnit from '../../../communicator/http/Company';
import { format_db_datetime, format_time_to_unix } from '../../../helper/misc';
import Message from '../../../elements/message';
import wrlog from '../../../helper/functions/wrlog';


const AdminUsersEdit = (props) => {

    // this.successNotification.
    const permissionUnit = new PermissionUnit();

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(true);

    const userId = params.id;

    const [isAdmin, setIsAdmin] = useState(false);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [departments, setDepartments] = useState([]);
    const [is404, setIs404] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [birthdate, setBirthdate] = useState(new Date());

    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();

    const companyUnit = new CompanyUnit();
    const userUnit = new UserUnit();

    useEffect(() => {

        if (params.id === undefined || params.id === null || params.id === "") {
            setIs404(true);
            return;
        }

        if (params.id === 'new') {
            companyUnit.listDepartments((res) => {
                setLoading(false);
                setDepartments(res)
            })
        } else {

            permissionUnit.load(params.id, (user) => {

                setLoading(false);

                setValue('firstName', user.userData.firstName);
                setValue('lastName', user.userData.lastName);
                setValue('birthdate', format_time_to_unix(user.userData.birthdate));
                setBirthdate(format_time_to_unix(user.userData.birthdate));
                setValue('department', user.userData.department);
                setSelectedDepartment(user.userData.department)
                setValue('email', user.userData.email);

                setUser(user.userData);

                let departments = user.permissions.departments;
                departments.sort((a, b) => {
                    return a.title - b.title;
                })

                setIsAdmin(user.permissions.isAdmin)

                setDepartments(departments)
            }, () => {
                setIs404(true);
            })
        }


    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'department') {
                setSelectedDepartment(value[name])
            }
            if (name === 'birthdate') {
                wrlog(value[name]);
                wrlog(format_db_datetime(value[name]).split(' ')[0])
                setBirthdate(value[name]);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const doSubmit = (data) => {

        setLoading(true)

        let body = {
            first_name: data.firstName,
            last_name: data.lastName,
            department: data.department,
            birthdate: format_db_datetime(birthdate).split(' ')[0],
            email: data.email,
        };

        if(data.password !== undefined && data.password !== null && data.password !== ''){
            body.password = data.password;
        }

        const cbSuccess = (res) => {
            setLoading(false)

            window.location.replace(`/user/${res.id}`);

        }
        const cbSuccessUpdate = (res) => {
            save();
            setUser(data);
        }

        const cbError = (err) => {
            setLoading(false)
            wrlog(err);
        }


        if (userId === 'new') {
            userUnit.create(body, cbSuccess, cbError);
        } else {
            body.id = userId;
            userUnit.update(body, cbSuccessUpdate, cbError);
        }
    }

    const save = () => {

        let _departments = [];
        departments.forEach(department => {
            _departments.push({
                id: department.id,
                can_read: department.canRead,
            })
        });

        let body = {
            user_id: userId,
            is_admin: isAdmin,
            departments: _departments
        }

        permissionUnit.set(body, (response) => {

            setLoading(false);

            setErrorMessageTitle(translate('saved_text'));
            setErrorMessageBody(translate('saved_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);

        }, (err) => { wrlog(err) })

    }

    const deleteUser = () => {

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            if (res[0] == 200) {
                window.location.replace(`/`);
            }
        }

        const cbError = (err) => {
            wrlog(err);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        userUnit.deleteUser(userId, cbSuccess, cbError);
    }

    if (is404 === true) {
        return (
            <><Link className="go_back_button" to="/">zurück</Link><br /><br />
                <h3>Der Benutzer wurde nicht gefunden</h3></>
        );
    }

    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{user.firstName} {user.lastName} {translate('edit')}</> : translate('add_new_user')}</h1>
                        {params.id !== "new" && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            {translate('delete_account')}
                        </div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col md={7}>
                        <form onSubmit={handleSubmit(doSubmit)}>
                            <FormController
                                name={"firstName"}
                                rule={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        {...register('firstName', { required: true })}
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.firstName}
                                        id="standard-basic"
                                        label={translate('firstname')}
                                    />
                                )}
                            />
                            <FormController
                                name={"lastName"}
                                rule={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        {...register('lastName', { required: true })}
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.lastName}
                                        id="standard-basic"
                                        label={translate('lastname')}
                                    />
                                )}
                            />
                            <FormController
                                name={"birthdate"}
                                rule={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <DatePicker
                                        {...register('birthdate', { required: true })}
                                        value={value}
                                        error={errors.birthdate}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        onChange={onChange}
                                        maximumDate={new Date()}
                                        label={translate('birthdate')}
                                        id="birthdate"
                                    />
                                )}
                            />
                            <FormControl
                                className="selectFormControl"
                                error={errors.department}
                                variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="type">{translate('department')}</InputLabel>

                                <Select
                                    {...register('department', { required: true })}
                                    label={translate('department')}
                                    error={errors.department}
                                    value={selectedDepartment}
                                >
                                    {
                                        departments.map(department => <MenuItem value={department.id}>{department.title}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormController
                                name={"email"}
                                rule={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        {...register('email', { required: true })}
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('email')}
                                        type="email"
                                    />
                                )}
                            />
                            <FormController
                                name={"password"}
                                rule={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        {...register('password', { required: false })}
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.password}
                                        id="standard-basic"
                                        label={translate('password')}
                                        type="password"
                                    />
                                )}
                            />
                            <input type="submit" style={{ width: '100%' }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                        </form>
                    </Col>
                    <Col md={5}>
                        {params.id !== "new" &&
                            <div className="scrollable_table">
                                <Table className="table_container scrollable rounded">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Abteilung</TableCell>
                                            <TableCell width={70}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            departments.map((department, key) => {

                                                return <TableRow key={key}>
                                                    <TableCell>
                                                        {department.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        {departments.length > 0 && <Checkbox
                                                            defaultChecked={department.canRead}
                                                            onChange={(v) => {
                                                                setDepartments(prev => {
                                                                    prev[key].canRead = !prev[key].canRead
                                                                    return prev;
                                                                })
                                                                wrlog(departments);
                                                            }}
                                                        />}
                                                    </TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        }

                        {params.id !== "new" &&
                            <Table className="table_container rounded" style={{ marginTop: 30 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Administrator</TableCell>
                                        <TableCell width={70}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Adminstrationsrechte
                                        </TableCell>
                                        <TableCell>
                                            {departments.length > 0 && <Checkbox
                                                checked={isAdmin}
                                                onChange={() => setIsAdmin(prev => !prev)}
                                            />}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
            </Container>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteUser()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default AdminUsersEdit;