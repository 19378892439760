const Pagination = ({ current, pages, pageChange }) => {

    let out = [];
    let pagesArray = []; 

    if (pages > 6) {

        if (current < 4) {
            pagesArray = [1, 2, 3, 4, '...'];
            pagesArray.push(pages);
        } else if(current === pages) {

            pagesArray = [1, '...', pages - 2, pages-1];
            pagesArray.push(pages);

        } else if(pages - 3 < current) {

            pagesArray = [1, '...'];
            for(let i = pages - 3; i < pages; i++){
                pagesArray.push(i);
            }
            pagesArray.push(pages);

        }else{
            pagesArray = [1, '...'];
            for (let i = current - 1; i < current + 2; i++) {
                pagesArray.push(i);
            }
            pagesArray.push('...');
            pagesArray.push(pages);
        }

    } else {
        for (let i = 1; i <= pages; i++) {
            pagesArray.push(i);
        }
    }

    pagesArray.forEach(page => {
        if (page === '...') {
            out.push(
                <div className={`paginationSpacer`}>...</div>
            );
        } else {

            out.push(
                <div
                    className={`paginationButton ${page === current ? 'current' : undefined}`}
                    onClick={() => pageChange(page)}
                >
                    {page}
                </div>
            );
        }
    });

    return <div className='paginationContainer'>
        {out}
    </div>

}

export default Pagination;