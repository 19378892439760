import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, InputLabel } from '@material-ui/core';

import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router';
import CertificateUnit from '../../communicator/http/Certificate';
import Popup from '../../elements/popup';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { format_datetime, format_db_datetime } from '../../helper/misc';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import { useForm, Controller as FormController } from "react-hook-form";

import Message from '../../elements/message';
import { translate } from '../../helper/multilingual';

registerLocale('de', de);
setDefaultLocale('de');

const AddCertificatePopup = ({open, close, userId, setLoading, refresh}) => {

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    const [manuallyType, setManuallyType] = useState(null);

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();

    const uploadRawCertificate = (data) => {

        const certificateUnit = new CertificateUnit();

        setLoading(true);

        let body = {
            type: data.type,
            user_id: userId
        }

        if (data.type === 'vaccination') {
            body.type_of_vaccination = data.typeOfVaccination;
            body.amount = data.amount;
            body.date_of_issue = format_db_datetime(data.issuedAt);
        } else if (data.type === 'recovery') {
            body.date_of_issue = format_db_datetime(data.firstPositiveTest);
        } else if (data.type === 'test') {
            body.type_of_test = data.typeOfTest;
            body.date_of_issue = format_db_datetime(data.issuedAt);
        }


        const cbSuccess = (res) => {
            setLoading(false);

            setErrorMessageTitle(res.title);
            setErrorMessageBody(res.body);
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);

            close();
            refresh();
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }


        certificateUnit.upload(body, cbSuccess, cbError)
    }


    return <>
        <Popup
            close={() => close()}
            open={open}
        >

            <h4>{translate('no_qr_code_found_text_1')}</h4>
            <div>{translate('no_qr_code_found_text_2')}</div>
            <div>{translate('no_qr_code_found_text_3')}</div>

            <FormControl
                className="selectFormControl"
                variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="type">{translate('type_of_certificate')}</InputLabel>
                <Select
                    label={translate('type_of_certificate')}
                    {...register('type', { required: true })}
                    type={'textinput'}
                    onChange={(val) => {
                        setManuallyType(val.target.value);
                    }}
                    error={errors.type !== undefined}
                >
                    <MenuItem value={"test"}>{translate('test')}</MenuItem>
                    <MenuItem value={"vaccination"}>{translate('vaccination')}</MenuItem>
                    <MenuItem value={"recovery"}>{translate('recovery')}</MenuItem>
                </Select>
            </FormControl>
            {
                manuallyType === 'vaccination' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>

                    <FormControl
                        variant="standard" sx={{ m: 1, minWidth: 120 }}
                        className="selectFormControl"
                        error={errors.typeOfVaccination}
                    >
                        <InputLabel id="type">{translate('type_of_vaccination')}</InputLabel>
                        <Select
                            {...register('typeOfVaccination', { required: true })}
                            label={translate('type_of_vaccination')}
                        >
                            <MenuItem value={"ORG-100001699"}>AstraZeneca AB</MenuItem>
                            <MenuItem value={"ORG-100030215"}>Biontech Manufacturing GmbH</MenuItem>
                            <MenuItem value={"ORG-100001417"}>Janssen-Cilag International</MenuItem>
                            <MenuItem value={"ORG-100031184"}>Moderna Biotech Spain S.L.</MenuItem>
                            <MenuItem value={"ORG-100006270"}>Curevac AG</MenuItem>
                            <MenuItem value={"ORG-100013793"}>CanSino Biologics</MenuItem>
                            <MenuItem value={"ORG-100020693"}>China Sinopharm International Corp. - Beijing location</MenuItem>
                            <MenuItem value={"ORG-100010771"}>Sinopharm Weiqida Europe Pharmaceutical s.r.o. - Prague location</MenuItem>
                            <MenuItem value={"ORG-100024420"}>Sinopharm Zhijun (Shenzhen) Pharmaceutical Co. Ltd. - Shenzhen location</MenuItem>
                            <MenuItem value={"ORG-100032020"}>Novavax CZ AS</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        className="selectFormControl"
                        error={errors.amount}
                        variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="type">{translate('vaccination_amount')}</InputLabel>
                        <Select
                            {...register('amount', { required: true })}
                            label={translate('vaccination_amount')}
                        >
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <p
                        className={`inputLabel ${errors.issuedAt ? 'error' : undefined}`}
                    >{translate('date_of_vaccination')}</p>
                    <FormController
                        name={"issuedAt"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('issuedAt', { required: true })}
                                name="issuedAt"
                                selected={value}
                                dateFormat="dd.MM.yyyy"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maximumDate={new Date()}
                            />
                        )}
                    />
                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />

                </form>
            }
            {
                manuallyType === 'recovery' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>

                    <p
                        className={`inputLabel ${errors.firstPositiveTest ? 'error' : undefined}`}
                    >{translate('first_positiv_test')}</p>
                    <FormController
                        name={"firstPositiveTest"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('firstPositiveTest', { required: true })}
                                name="firstPositiveTest"
                                selected={value}
                                dateFormat="dd.MM.yyyy"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maxDate={new Date()}
                            />
                        )}
                    />

                    <br />
                    <br />

                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />

                </form>
            }

            {
                manuallyType === 'test' &&
                <form onSubmit={handleSubmit(uploadRawCertificate)}>
                    <FormControl
                        className="selectFormControl"
                        error={errors.typeOfTest}
                        variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="type">{translate('type_of_test')}</InputLabel>
                        <Select
                            {...register('typeOfTest', { required: true })}
                            label={translate('type_of_test')}
                            error={errors.typeOfTest}
                        >
                            <MenuItem value={"pcr"}>{translate('pcr')}</MenuItem>
                            <MenuItem value={"antigen"}>{translate('antigen')}</MenuItem>
                        </Select>
                    </FormControl>

                    <br />
                    <br />
                    <p
                        className={`inputLabel ${errors.issuedAt ? 'error' : undefined}`}
                    >{translate('date_of_sign_test')}</p>
                    <FormController
                        name={"issuedAt"}
                        rule={{
                            required: true,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                            <DatePicker
                                {...register('issuedAt', { required: true })}
                                name="issuedAt"
                                selected={value}
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="dd.MM.yyyy hh:mm"
                                onChange={(res) => {
                                    onChange(res)
                                }}
                                maxDate={new Date()}
                            />
                        )}
                    />


                    <input type="submit"
                        className="button" style={{ width: '100%', marginTop: 30 }}
                        value={translate('upload_certificate')}
                    />
                </form>
            }

        </Popup>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
    </>

}

export default AddCertificatePopup;