import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CompanyUnit from '../../communicator/http/Company';
import UserUnit from '../../communicator/http/User';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import { translate } from '../../helper/multilingual';
import TopBar from '../../elements/topbar';
import Pagination from '../../elements/pagination';
import AddCertificatePopup from './AddCertificatePopup';
import { CSVLink } from "react-csv";
import Button from '../../elements/button';
import createCsv from './createXlsx';
import CertificateSelector from '../../elements/certificateSelector';

const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);

    const [filterActive, setFilterActive] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState('');
    const [sortStatus, setSortStatus] = useState(null);
    const [name, setName] = useState('');
    const [sorts, setSorts] = useState([]);
    const [sort, setSort] = useState('last_name_asc');
    const [filter, setFilter] = useState('');
    const [departments, setDepartments] = useState([]);


    const [chooseUploadTypePopupOpen, setChooseUploadTypePopupOpen] = useState(false);
    const [showAddCertificatePopup, setShowAddCertificatePopup] = useState(false);
    const [uploadCertificateUser, setUploadCertificateUser] = useState({});

    const [stats, setStats] = useState({
        valid: null,
        invalid: null,
        inReview: null,
        total: null,
    })

    const [page, setPage] = useState(1);

    const csvLinkEl = useRef();

    const perPage = 25;

    const companyUnit = new CompanyUnit();
    const userUnit = new UserUnit();

    useEffect(() => {
        userUnit.getUserData(null, (res) => {
            setName(res.firstName + ' ' + res.lastName)
        }, () => { });
    }, []);

    useEffect(() => {

        if (page > 0) {
            loadUsers();
        }

    }, [page]);

    useEffect(() => {

        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)

    }, [sortStatus, filter, sort]);

    useEffect(() => {
        setSorts([
            {
                id: 'last_name_asc',
                value: { by: 'last_name', direction: 'ASC' },
                title: 'Nachname aufsteigend'
            },
            {
                id: 'last_name_desc',
                value: { by: 'last_name', direction: 'DESC' },
                title: 'Nachname absteigend'
            },
            {
                id: 'first_name_asc',
                value: { by: 'first_name', direction: 'ASC' },
                title: 'Vorname aufsteigend'
            },
            {
                id: 'first_name_desc',
                value: { by: 'first_name', direction: 'DESC' },
                title: 'Vorname absteigend'
            }
        ]);

        companyUnit.listDepartments((res) => {
            setDepartments(res)

            if (res.length > 1) {
                setSorts(prev => [...prev, {
                    id: 'departments_asc',
                    value: { by: 'departments', direction: 'ASC' },
                    title: 'Abteilung aufsteigend'
                },
                {
                    id: 'departments_desc',
                    value: { by: 'departments', direction: 'DESC' },
                    title: 'Abteilung absteigend'
                }])
            }
        })
    }, [])

    const doSearch = (e) => {
        e.preventDefault();
        setFilterActive(true);

        if (searchNameTerm !== "") {
            loadUsers();
        }
    }

    const loadUsers = () => {

        if (page < 1) {
            return false;
        }

        setLoading(true);

        let queryArgs = {
            take: perPage,
            skip: (page - 1) * perPage,
            search_term: searchNameTerm,
            status: sortStatus
        };

        if (filter !== '') {
            queryArgs['filter'] = { department: filter };
        }

        if (sort !== '') {
            sorts.forEach(_sort => {
                if (_sort.id === sort) {
                    queryArgs['sort'] = _sort.value;
                }
            })
        }

        companyUnit.listUsers(queryArgs, (res) => {

            setLoading(false);

            setSelectedUsers(res.users);

            // let valid = 0;
            // let invalid = 0;
            // let inReview = 0;

            // res.users.forEach(user => {
            //     if (user.status === 'valid') {
            //         valid++;
            //     } else if (user.status === 'invalid') {
            //         invalid++;
            //     }
            //     if (user.hasCertificatesInReview) {
            //         inReview++;
            //     }
            // });

            setStats({
                valid: res.statistics.valid,
                invalid: res.statistics.invalid,
                inReview: res.statistics.in_review,
                total: res.statistics.total
            })

        }, (err) => {
            setLoading(false);
        })
    }

    /**
         * receives the csv file
         * 
         */
    const getCsv = () => {

        setCsvLoading(true);

        companyUnit.getCsv((res) => {
            setCsvLoading(false);
            createCsv(res.overview, res.departments, res.users, res.certificates, res.all_certificates);
        }, (err) => {
            setCsvLoading(false);
        })
    }



    const translateStatus = (status) => {
        switch (status) {
            case 'valid':
                return "gültig";
            case 'invalid':
                return "ungültig";
            case 'in_review':
                return "zu überprüfen";
        }
    }

    return <>
        <Container fluid>
            <TopBar name={name} logout={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('isAdmin');
                window.location.reload();
            }} />
            <Row style={{ marginTop: 44 }}>
                <Col md={8}>

                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>

                </Col>
            </Row>
            <form onSubmit={doSearch}>
                <Row style={{ marginTop: 44 }}>
                    <Col md={4}>
                        <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Personen durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                    </Col>
                    <Col md={2}>
                        <div
                            onClick={doSearch}
                            style={{ paddingTop: 17, paddingBottom: 18, marginRight: 10 }}
                            className={`button ${searchNameTerm == '' ? 'disabled' : ''}`}>
                            suchen
                        </div>
                    </Col>
                    <Col md={3}>
                        {/* <FormControl
                            className="selectFormControl"
                            variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="type">Sortieren</InputLabel>
                            <Select
                                label={'Sortieren'}
                                type={'textinput'}
                                onChange={(val) => {
                                    setSort(val.target.value)
                                }}
                                value={sort}
                            >
                                {
                                    sorts.map(sort => <MenuItem value={sort.id}>{sort.title}</MenuItem>)
                                }

                            </Select>
                        </FormControl> */}
                        {departments.length > 1 && <FormControl
                            className="selectFormControl"
                            variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="type">{translate('department')}</InputLabel>
                            <Select
                                label={translate('department')}
                                type={'textinput'}
                                onChange={(val) => {
                                    setFilter(val.target.value)
                                    setFilterActive(true);
                                }}
                                value={filter}
                            >
                                <MenuItem value={''}>Alle Abteilungen</MenuItem>
                                {
                                    departments.map(department => <MenuItem value={department.id}>{department.title}</MenuItem>)
                                }

                            </Select>
                        </FormControl>
                        }
                    </Col>
                    <Col md={3}>
                        {sessionStorage.getItem('isAdmin') === "true" &&
                            <Button
                                title={csvLoading ? 'wird generiert...' : 'Bericht herunterladen'}
                                loading={csvLoading}
                                onClick={getCsv}
                            />
                        }

                    </Col>
                </Row>

            </form>
            <Row style={{ marginTop: 44 }}>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("valid");
                        }}
                    >
                        <div className="cardHeadline green">{stats.valid}</div>
                        <div className="cardLabel">Gültig</div>
                    </div>
                </Col>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("invalid");
                        }}
                    >
                        <div className="cardHeadline red">{stats.invalid}</div>
                        <div className="cardLabel">Ungültig</div>
                    </div>
                </Col>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("inReview");
                        }}
                    >
                        <div className="cardHeadline yellow">{stats.inReview}</div>
                        <div className="cardLabel">Zu überprüfen</div>
                    </div>
                </Col>
                <Col md={3} style={{ alignItems: 'center' }}>
                    {filterActive && <>
                        <div
                            className="card"
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxWidth: 142,
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                setSortStatus(null);
                                setFilterActive(false);
                                setSearchNameTerm('');
                                setFilter('');

                                setPage(0);
                                setTimeout(() => {
                                    setPage(1);
                                }, 100)
                            }}
                        >
                            <div className="cardLabel red">Filter zurücksetzen</div>
                        </div>
                    </>
                    }
                </Col>
            </Row>
        </Container>
        <Container fluid>
            {
                sessionStorage.getItem('isAdmin') === "true" &&
                <Row style={{ marginTop: 44, textAlign: 'right' }}>
                    <Col md={12}>
                        <Link to={'/user/new'} className={'button'}>{translate('add_new_user')}</Link>
                    </Col>
                </Row>
            }
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell>Abteilung</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedUsers.length > 0 && selectedUsers.map((user, key) => {

                                    let tooltipItems = [
                                        {
                                            label: translate('upload_certificate'),
                                            action: () => {
                                                setChooseUploadTypePopupOpen(true);
                                                setUploadCertificateUser({ ...user });
                                            }
                                        }
                                    ];


                                    if (sessionStorage.getItem('isAdmin') === "true") {
                                        tooltipItems.push({
                                            label: translate('permissions'),
                                            to: "/user/" + user.id,
                                        })
                                    }

                                    if (user.hasCertificatesInReview) {
                                        tooltipItems.push({
                                            label: translate('check_certificate'),
                                            to: "check/" + user.id
                                        });
                                    }

                                    return <TableRow key={key}>
                                        <TableCell>
                                            {user.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {user.firstName}
                                        </TableCell>
                                        <TableCell>
                                            <a href={`mailto:${user.email}`}>{user.email}</a>
                                        </TableCell>
                                        <TableCell>
                                            {user.department}
                                        </TableCell>
                                        <TableCell>
                                            {user.isAdmin}
                                        </TableCell>
                                        <TableCell>
                                            {tooltipItems.length > 0 && <ToolTip items={tooltipItems} />}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>

                                            {
                                                user.hasCertificatesInReview && <div style={{ marginRight: 10 }} className={`statusBadge in_review`}>{translateStatus('in_review')}</div>
                                            }

                                            <div className={`statusBadge ${user.status}`}>{translateStatus(user.status)}</div>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Container className="" fluid>
            <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                    <Pagination
                        pages={Math.ceil(stats.total / perPage)}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>


        <Container className="footer" fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <a target="_blank" href="https://3garbeitsplatz.com/datenschutz">Datenschutz</a>
                    <a target="_blank" href="https://3garbeitsplatz.com/impressum">Impressum</a>
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
        {showAddCertificatePopup &&
            <AddCertificatePopup
                open={showAddCertificatePopup}
                close={() => setShowAddCertificatePopup(false)}
                setLoading={setLoading}
                userId={uploadCertificateUser}
                refresh={loadUsers}
            />
        }
        <CertificateSelector
            user={uploadCertificateUser}
            setLoading={setLoading}
            setChooseUploadTypePopupOpen={setChooseUploadTypePopupOpen}
            chooseUploadTypePopupOpen={chooseUploadTypePopupOpen}
        />
    </>

}

export default Dashboard;