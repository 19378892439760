import React, { Component, useState, useEffect } from 'react';
import styles from './style.module.scss';

const Button = ({ title, onClick, loading }) => {



    return <>
        <div
            className={`button ${styles.button} ${loading ? styles.loading : undefined}`}
            onClick={onClick}
        >
            {
                title
            }
            {
                loading && <div className={styles.spinnerRolling}>
                    <div className={styles.ldio}>
                        <div></div>
                    </div>
                </div>

            }
        </div>




    </>

}

export default Button;