import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CheckUnit from '../../communicator/http/Check';
import UserUnit from '../../communicator/http/User';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import { Link, useParams } from 'react-router-dom';
import { format_datetime, format_time_from_db } from '../../helper/misc';
import { translate } from '../../helper/multilingual';
import wrlog from '../../helper/functions/wrlog';


const Check = ({ setCheckCount }) => {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [certificate, setCertificate] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const userId = useParams().id;

    const certificatesUnit = new CheckUnit();

    useEffect(() => {

        loadCertificates();

    }, []);

    const loadCertificates = () => {
        certificatesUnit.listCertificates((_users) => {
            setUsers(_users)

            if (userId !== undefined) {
                setSelectedUser(userId);

                _users.forEach((user, userKey) => {
                    if (user.id == userId) {
                        const _certificate = {
                            ...user.certificates[0],
                            firstName: user.firstName,
                            lastName: user.lastName
                        };

                        setCertificate(_certificate);
                    }
                });

                setLoading(false)
            } else {
                setLoading(false)
            }

        }, () => {
            setLoading(false)
        })
    }

    const selectCertificate = (userKey, certificateKey = 0) => {

        const _certificate = {
            ...users[userKey].certificates[certificateKey],
            firstName: users[userKey].firstName,
            lastName: users[userKey].lastName
        };

        setCertificate(_certificate);
    }

    const acceptCertificate = () => {
        setLoading(true);
        wrlog(certificate.id)

        certificatesUnit.acceptCertificate(certificate.id, (res) => {
            loadCertificates();
            setCertificate(null);
            setCheckCount(parseInt(sessionStorage.getItem('checkCount')) - 1);
        }, (err) => {
            setLoading(false);
        })
    }

    const denyCertificate = () => {
        setLoading(true);
        wrlog(certificate.id)

        certificatesUnit.denyCertificate(certificate.id, (res) => {
            loadCertificates();
            setCertificate(null);
            setCheckCount(parseInt(sessionStorage.getItem('checkCount')) - 1);
        }, (err) => {
            setLoading(false);
        })
    }

    const renderVaccinationDetails = (data) => {
        return <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Nachname</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Vorname</TableCell>
                    <TableCell>{data.firstName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Anzahl der Impfungen</TableCell>
                    <TableCell>{data.amount}/{data.maxAmount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Hersteller der Impfung</TableCell>
                    <TableCell>{data.type_of_vaccination}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Ausstellungsdatum</TableCell>
                    <TableCell>{format_datetime(data.date_of_issue)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    const renderRecoveryDetails = (data) => {
        return <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Nachname</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Vorname</TableCell>
                    <TableCell>{data.firstName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Datum des ersten Positiv-Tests</TableCell>
                    <TableCell>{format_datetime(data.date_of_issue)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    const renderTestDetails = (data) => {
        return <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Nachname</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Vorname</TableCell>
                    <TableCell>{data.firstName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Art des Tests</TableCell>
                    <TableCell>{data.type_of_test}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Gültig ab</TableCell>
                    <TableCell>{format_datetime(data.date_of_issue)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    return <>
        <div className='innerContainerWrapper'>
            <div className='secondSidebar small'>
                <Table className="table_container">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ height: 72 }}>{translate('name')}</TableCell>
                            <TableCell>{translate('department')}</TableCell>
                            <TableCell>{translate('birthdate')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users.map((user, key) => {
                                return <TableRow key={key} className={selectedUser === user.id ? "selectedRow" : ""} style={{ cursor: 'pointer' }} onClick={() => {
                                    selectCertificate(key);
                                    setSelectedUser(user.id);
                                }}>
                                    <TableCell>
                                        {user.lastName} {user.firstName}
                                    </TableCell>
                                    <TableCell>
                                        {user.department}
                                    </TableCell>
                                    <TableCell>
                                        {format_time_from_db(user.birthdate).date}
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            <div className='containerWithSecondSidebar small' style={{}}>
                {certificate !== null && <div className="" style={{ paddingLeft: 30 }}>
                    <h4>Zertifikat von {certificate.firstName} {certificate.lastName}</h4>
                    {
                        certificate.type === 'vaccination' && renderVaccinationDetails({ ...certificate })
                    }
                    {
                        certificate.type === 'recovery' && renderRecoveryDetails({ ...certificate })
                    }
                    {
                        certificate.type === 'test' && renderTestDetails({ ...certificate })
                    }
                    <br />
                    <br />
                    <br />
                    <h5>Hochgeladenes Zertifikat</h5>
                    <img style={{ backgroundColor: 'white', width: '100%', marginBottom: 70, boxShadow: '0 3px 20px rgba(0,0,0,0.08)' }} src={certificate.certificate_file} />
                </div>}
            </div>
        </div>
        {certificate !== null &&
            <>
                <div
                    onClick={acceptCertificate}
                    className="button"
                    style={{ width: 'auto', position: 'fixed', bottom: 30, right: 30, color: 'var(--text-color)', fontWeight: 500 }}
                >
                    Akzeptieren
                </div>
                <div
                    onClick={denyCertificate}
                    className="button"
                    style={{ width: 'auto', position: 'fixed', bottom: 30, right: 200, backgroundColor: '#F29696', color: 'var(--text-color)', fontWeight: 500 }}
                >
                    Ablehnen
                </div>
            </>
        }
        <Loading visible={loading} />
    </>

}

export default Check;