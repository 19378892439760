import { Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { validation } from '../../helper/validation';
import Notification from '../../helper/screens/Notification';
import Popup from '../../helper/screens/Popup';

import CustomCheckbox from '../../helper/screens/CustomCheckbox';
import UserUnit from '../../communicator/http/User';
import ValidityUnit from '../../communicator/http/Validity';
import { removeFromArrayByValue } from '../../helper/functions/array';

import validityTypes from './validityTypes.json';
import validityKeys from './validityKeys.json';

import saveButton from '../../assets/icons/save.png';
import deleteButton from '../../assets/icons/trash.png';
import { translate } from '../../helper/multilingual';

import NotFound from '../../assets/illustrations/not_found.png';
import wrlog from '../../helper/functions/wrlog';

const Validities = (props) => {

    // this.successNotification.
    const validityUnit = new ValidityUnit();

    const successNotification = useRef();
    const errorNotification = useRef();

    const [loading, setLoading] = useState(true);
    const [rulesets, setRulesets] = useState([]);

    const [ruleset, setRuleset] = useState({});
    const [newRule, setNewRule] = useState({});
    const [newRuleShow, setNewRuleShow] = useState(true);

    const [currentRuleKind, setCurrentRuleKind] = useState(null);

    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {

        loadValidities()

    }, []);

    const loadValidities = (cb = () => { }) => {
        
        setLoading(true);

        validityUnit.list((_rulesets) => {

            setLoading(false);

            _rulesets.forEach((ruleset, key) => {
                _rulesets[key].kind = getKindOfType(ruleset.type);
            });

            setRulesets(_rulesets);

            cb(_rulesets);

        }, () => {
            setLoading(false);
        })
    }

    const addRule = (rulesetId) => {
        
        setLoading(true);

        const cb = (res) => {

            res.forEach(__ruleset => {
                if (__ruleset.id === rulesetId) {
                    setRuleset(__ruleset);
                }
            });

            setNewRuleShow(false);
            setNewRule(
                {
                    rulesetId: rulesetId,
                    id: -1,
                    type: null,
                    key: null,
                    kind: null,
                    value: null,
                    invalidMessage: null,
                }
            )

            setTimeout(() => setNewRuleShow(true), 200);
        }

        validityUnit.changeRules({
            action: 'add_rule',
            ruleset_id: rulesetId,
            type: newRule.type,
            key: newRule.key,
            value: newRule.value,
            invalid_message: newRule.invalidMessage,
        }, () => {
            loadValidities(cb);
        }, (err) => {
            wrlog(err);
            setLoading(false);
        })
    }


    const create = () => {

        setLoading(true);

        if (ruleset.id === -1) {
            validityUnit.create({ company_id: companyId }, (rulesetId) => {
                addRule(rulesetId);
                setRuleset(prev => {
                    prev.id = rulesetId;
                    return prev;
                })
                setLoading(false);

            }, (err) => {
                wrlog(err)
                setLoading(false);
            })
        } else {
            addRule(ruleset.id)
            setLoading(false);
        }

    }

    const deleteRule = (ruleId) => {

        setLoading(true);

        const rulesetId = ruleset.id;

        validityUnit.changeRules({
            action: 'delete_rule',
            rule_id: ruleId,
            ruleset_id: rulesetId
        }, () => {
            loadValidities((res) => {

                setRuleset({});
                setLoading(false);

                res.forEach(_ruleset => {
                    if (_ruleset.id == rulesetId) {
                        setRuleset(_ruleset);
                    }
                });

            });
        }, (err) => { 
            wrlog(err);
            setLoading(false);
        })

    }

    const deleteRuleset = () => {

        setLoading(true);

        validityUnit.remove(ruleset.id, () => {
            loadValidities(() => {
                setLoading(false);
                setRuleset({});
            });
        }, (err) => {
            setLoading(false);
            wrlog(err);
        })

    }

    const getKindOfType = (_type) => {

        let out = null;

        validityTypes.forEach(type => {
            if (type.value == _type) {
                out = type.kind;
            }
        })

        return out;
    }

    const getLabelOfType = (_type) => {

        let out = null;

        validityTypes.forEach(type => {
            if (type.value == _type) {
                out = type.label;
            }
        })

        return out;
    }

    const getLabelOfKey = (_key) => {

        let out = null;

        validityKeys.forEach(key => {
            if (key.value == _key) {
                out = key.label;
            }
        })

        return out;
    }


    // Create timeIntervals
    let timeIntervals = [];
    for (let i = 1; i < 24; i++) {
        timeIntervals.push({
            value: i + ' ' + (i === 1 ? 'hour' : 'hours'),
            label: i + ' ' + (i === 1 ? translate("single_hour") : translate("hours"))
        })
    }

    for (let i = 1; i <= 365; i++) {
        timeIntervals.push({
            value: i + ' ' + (i === 1 ? 'day' : 'days'),
            label: i + ' ' + (i === 1 ? translate("day") : translate("days"))
        })
    }

    for (let i = 2; i <= 5; i++) {
        timeIntervals.push({
            value: i + ' ' + (i === 1 ? 'year' : 'years'),
            label: i + ' ' + (i === 1 ? translate("year") : translate("years"))
        })
    }

    return (
        <div className='innerContainerWrapper'>
            <div className='secondSidebar'>
                <Table className="table_container">
                    <TableHead style={{ position: 'sticky', top: 0 }}>
                        <TableRow>
                            <TableCell>{translate('rulesets')}</TableCell>
                            <TableCell>
                                <div className="button" style={{
                                    width: 40,
                                    height: 40,
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    float: 'right'
                                }} onClick={() => {
                                    setRuleset({
                                        id: -1,
                                        rules: [
                                        ]
                                    })

                                    setNewRule(
                                        {
                                            rulesetId: -1,
                                            id: -1,
                                            type: null,
                                            key: null,
                                            kind: null,
                                            value: null,
                                            invalidMessage: null,
                                        }
                                    )

                                }}>+</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rulesets.map((currentRuleset, key) => {
                                return <TableRow className={`rowToSelect ${currentRuleset.id === ruleset.id ? "selectedRow" : ""}`} key={key} style={{ cursor: 'pointer' }} onClick={() => {

                                    if (ruleset.id === currentRuleset.id) return false;

                                    setRuleset(prev => ({ ...currentRuleset }))

                                    setNewRule(
                                        {
                                            rulesetId: currentRuleset.id,
                                            id: -1,
                                            type: null,
                                            key: null,
                                            kind: null,
                                            value: null,
                                            invalidMessage: null,
                                        }
                                    )


                                }}>
                                    <TableCell colSpan={2}>
                                        {currentRuleset.rules.map((rule, key) => {
                                            return <>
                                                {getLabelOfType(rule.type)} {getLabelOfKey(rule.key)}
                                                {key < currentRuleset.rules.length - 1 && <>
                                                    <br />
                                                    <br />
                                                </>
                                                }
                                            </>
                                        })}
                                    </TableCell>
                                </TableRow>
                            })
                        }

                    </TableBody>
                </Table>
            </div>
            <div className='containerWithSecondSidebar'>
                {ruleset !== undefined && ruleset.id !== undefined ? <> <Container fluid>
                    <Row>
                        <Col md={8}>
                            {/* <Link className="go_back_button" to="/">zurück</Link><br /><br /> */}
                            <h1>{translate('edit_ruleset')}</h1>
                        </Col>
                        <Col md={4} style={{ textAlign: 'right' }}>
                            <div class="button delete" onClick={() => {
                                deleteRuleset()
                            }}>{translate('delete_ruleset')}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ marginTop: 20, marginBottom: 30 }}>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>

                            <Container fluid style={{ alignItems: 'center' }}>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col md={4}>Typ</Col>
                                    <Col md={5}>Regel</Col>
                                    <Col md={2}>Wert</Col>
                                    <Col md={1}></Col>
                                </Row>
                                {
                                    ruleset.rules.map((rule, key) => {

                                        let renderedValue = rule.value;

                                        if (rule.value.split(' ').length === 2) {
                                            let splitValue = rule.value.split(' ');
                                            renderedValue = `${splitValue[0]} ${translate(splitValue[1])}`;
                                        }

                                        return <Row style={{ paddingTop: 10, paddingBottom: 10, background: (key % 2 == 0 ? 'white' : 'transparent'), alignItems: 'center' }}>
                                            <Col md={4}><small>{getLabelOfType(rule.type)}</small></Col>
                                            <Col md={5}><small>{getLabelOfKey(rule.key)}</small></Col>
                                            <Col md={2}>{renderedValue}</Col>
                                            <Col md={1}>
                                                <div class="button iconButton delete" onClick={() => {
                                                    deleteRule(rule.id)
                                                }}><img src={deleteButton} /></div>

                                            </Col>
                                        </Row>

                                    })
                                }
                                {newRuleShow && <Row style={{ paddingTop: 10 }}>
                                    <Col md={4}>
                                        <FormControl
                                            className="selectFormControl"
                                            variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="type">Typ auswählen</InputLabel>

                                            <Select
                                                label={"Typ auswählen"}
                                                onChange={(e) => {
                                                    setNewRule(prev => {
                                                        prev.type = e.target.value;
                                                        prev.kind = getKindOfType(e.target.value);
                                                        return prev;
                                                    })
                                                    setCurrentRuleKind(getKindOfType(e.target.value));
                                                }}
                                            >
                                                {
                                                    validityTypes.map(type => {
                                                        return <MenuItem selected={type.value == newRule.type} value={type.value}>{type.label}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md={5}>

                                        <FormControl
                                            className="selectFormControl"
                                            variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="type">Regel auswählen</InputLabel>

                                            <Select
                                                label={"Regel auswählen"}
                                                onChange={(e) => {
                                                    setNewRule(prev => {
                                                        prev.key = e.target.value;
                                                        return prev;
                                                    })
                                                }}
                                            >
                                                {
                                                    validityKeys.map(validityKey => {

                                                        if (validityKey.kind != currentRuleKind) {
                                                            return null;
                                                        }

                                                        return <MenuItem selected={validityKey.value == newRule.key} value={validityKey.value}>{validityKey.label}</MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </FormControl>

                                    </Col>
                                    <Col md={2}>


                                        <FormControl
                                            className="selectFormControl"
                                            variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="type">Zeitspanne</InputLabel>

                                            <Select
                                                label={"Zeitspanne"}
                                                onChange={(e) => {
                                                    setNewRule(prev => {
                                                        prev.value = e.target.value;
                                                        return prev;
                                                    })
                                                }}
                                            >

                                                {
                                                    timeIntervals.map(timeInterval => {
                                                        return <MenuItem value={timeInterval.value}>{timeInterval.label}</MenuItem>
                                                    })
                                                }


                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md={1}>
                                        <div class="button iconButton" onClick={() => {
                                            create()
                                        }}><img src={saveButton} /></div>
                                    </Col>
                                </Row>
                                }
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>

                        </Col>
                    </Row>
                </Container>
                </> : <>
                    <Container>
                        <Row>
                            <Col style={{ paddingTop: 100, textAlign: 'center', alignItems: 'center' }}>
                                <img src={NotFound} style={{ width: 400 }} /><br />
                                <h4 style={{ maxWidth: 700, display: 'inline-block' }}>{translate('please_choose_a_ruleset')}</h4>
                            </Col>
                        </Row>
                    </Container>
                </>}

            </div>
            <Notification ref={successNotification} text="Added User successfully" type="success" />
            <Notification ref={errorNotification} text="Something went wrong. Please try again." type="error" />
            <Loading visible={loading} />
        </div >

    )

}

export default Validities;