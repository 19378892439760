import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class CompanyUnit extends Unit {

    constructor() {
        super();
    }

    listUsers = (body, cbSuccess, cbError) => {
        this.post('/company/filter', body, cbSuccess, cbError);
    }

    listDepartments = (cbSuccess, cbError) => {
        this.get('/departments', cbSuccess, cbError);
    }

    getCsv = (cbSuccess, cbError) => {
        this.get('/company/csv', cbSuccess, cbError);
    }
}



