import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class CertificateUnit extends Unit {

    constructor() {
        super();
    }

    upload = (body, cbSuccess, cbError) => {
        this.post('/certificate', body, cbSuccess, cbError);
    }

}



