import * as XLSX from "xlsx";
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

const createCsv = (overview, departments, users, certificates, all_certificates) => {

    let workbook = XLSX.utils.book_new();

    // Process Data (add a new row)
    // var worksheet = workbook.Sheets["Sheet1"];

    var worksheet_overview = XLSX.utils.aoa_to_sheet(overview);
    var worksheet_departments = XLSX.utils.aoa_to_sheet(departments);
    var worksheet_users = XLSX.utils.aoa_to_sheet(users);
    var worksheet_certificates = XLSX.utils.aoa_to_sheet(certificates);
    var worksheet_all_certificates = XLSX.utils.aoa_to_sheet(all_certificates);

    XLSX.utils.book_append_sheet(workbook, worksheet_overview, 'Übersicht');
    XLSX.utils.book_append_sheet(workbook, worksheet_departments, 'Abteilungen');
    XLSX.utils.book_append_sheet(workbook, worksheet_users, 'Alle Mitarbeiter');
    XLSX.utils.book_append_sheet(workbook, worksheet_certificates, 'Gültige Zertifikate');
    XLSX.utils.book_append_sheet(workbook, worksheet_all_certificates, 'Alle Zertifikate');

    XLSX.writeFile(workbook, "Bericht_3G-Arbeitsplatz.xlsx");
}

export default createCsv;